@use "theme/palette"
.probeListSession-box
  display: flex
  height: 100% // Full viewport height
  width: 100%
  flex-direction: row
  align-items: center
  background: palette.$white
  border-radius: 1.5rem
  border: none
  outline: none
  .noDataTextContainer
    display: flex
    width: 100%
    height: 100%
    //border: 2px solid red
    justify-content: center
    align-items: center

.left-half, .right-half
  flex: 1 // Each half takes up equal space
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  padding: 10px
  box-sizing: border-box
  border-radius: 1.5rem

.left-half
  background: palette.$white // Light gray background for the left half
  overflow-y: auto // Allow vertical scrolling
  height: 100% // Ensure it takes the full height of the container
  width: 50% // Ensure it takes half the width of the container
  max-height: 100% // Ensure the height does not exceed the container height

.right-half
  background-color: #ffffff // White background for the right half
  overflow-y: auto // Allow vertical scrolling
  height: 100% // Ensure it takes the full height of the container
  width: 50% // Ensure it takes half the width of the container
  max-height: 100% // Ensure the height does not exceed the container height

.probe-item
  padding: 10px
  margin: 5px 0
  background-color: #fff
  border: 3px solid #ddd
  border-radius: 3px
  cursor: pointer
  width: 100% // Ensure the probe items take up the full width
  &:hover
    background-color: #f0f0f0

.probe-item.selected
  border: 3px solid #1870d5 // Highlight selected item with a blue border

.probe-details
  width: 100%
  padding: 10px
  background-color: #fff
  //border: 1px solid #ddd
  //border-radius: 3px

.session-item
  padding: 5px
  margin: 5px 0
  color: #f0f0f0
  background-color: #1870d5
  border: 1px solid #ccc
  border-radius: 3px
  cursor: pointer
  &:hover
    opacity: .9

.session-item.selected
    border: 3px solid #000000 // Highlight selected item with a blue border
