@use "theme/palette"
.home
    width: 100%
    height: 100vh
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: flex-start
    &-left-block
        width: 50%
        height: 100%
        background-color: #1870d5
        @media screen and ( max-width: 70rem )
            width: 0
            height: 0
            opacity: 0
    &-right-block
        width: 50%
        height: 100%
        background-color: #FCF9F9
        padding: 25px 40px
        overflow: auto
        display: flex
        flex-direction: column
        justify-content: flex-start
        @media screen and ( max-width: 70rem )
            width: 100%
        .home-page-switcher
            display: flex
            justify-content: flex-end
            margin-bottom: 10%
            &-item
                background-color: palette.$light-blue
                color: #9da6b1
                padding: 10px 25px
                cursor: pointer
                font-size: 0.9em
                border: none
                outline: none
                display: inline-block
                text-decoration: none
                font-weight: 700
            &-item-active
                background-color: #1870d5
                color: white
            &-item:first-child
                border-top-left-radius: 25px
                border-bottom-left-radius: 25px
            &-item:last-child
                border-top-right-radius: 25px
                border-bottom-right-radius: 25px
    &-login-panel
        width: 100%
        height: max-content

.FormTitle
    color: #707c8b
    font-weight: 300
    margin-bottom: 50px
    &_Link
        color: #707c8b
        text-decoration: none
        display: inline-block
        font-size: 1.7em
        margin: 0 10px
        padding-bottom: 5px
    &_Link:first-child
        margin-left: 0
    &_Link-Active
        color: #1870d5
        border-bottom: 1px solid #1870d5

.FormHeader
    color: #707c8b
    
    font-size: 2.0em
    margin-bottom: 50px
    