.confirmation-modal
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.5)
  display: flex
  justify-content: center
  align-items: center

.confirmation-modal-content
  background-color: #fff
  padding: 20px
  border-radius: 5px
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1)
  text-align: center

.confirmation-modal-buttons
  display: flex
  justify-content: space-around
  margin-top: 20px

.confirm-button
  padding: 10px 20px
  background-color: #d9534f
  color: #fff
  border: none
  border-radius: 5px
  cursor: pointer
  &:hover
    background-color: #c9302c

.cancel-button
  padding: 10px 20px
  background-color: #5bc0de
  color: #fff
  border: none
  border-radius: 5px
  cursor: pointer
  &:hover
    background-color: #31b0d5