.skip-main-container
    width: 100%
    height: 90vh
    display: flex
    flex-direction: column
    //border: 2px solid red
.skip-top-form
    display: flex
    flex: 1
    align-items: center
    padding: 0px 10px 0px 10px
    //border: 2px solid green
.skip-bottom-form    
    flex: 10
    text-align: center
    //border: 2px solid blue
.skip-form-container
    width: 100%
    height: 17%
    display: flex
    flex-direction: column
    padding-left: 80px
    //border: 2px solid green
.skip-btn-container
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 10px
    margin-bottom: 2px
    //border: 2px solid purple
.skip-form-title 
    width: 85%
    display: block
    text-transform: uppercase
    font-size: 0.9em
    color: #1870d5
    text-align: left
.skip-input
    width: 85%
    background-color: transparent
    color: #707c8b
    border: 1px solid #1870d5
    border-radius: 10px
    font-size: 1em
    font-weight: 400
    padding: 10px
.skip-title
    margin-bottom: 0px
    //border: 2px solid black
.skip-txt
    margin-top: 5px
    //border: 2px solid black
.skip-error
    color: red
    margin-top: 2px
    margin-bottom: 0px
    text-align: left
.skip-back-btn
    background-color: #1870d5
    color: white
    border: none
    border-radius: 25px
    padding: 10px 20px 10px 20px
    font-size: 0.8em
    font-weight: 600
    &:hover
        opacity: 0.8
        cursor: pointer
    &:active
        opacity: 0.5
.skip-send-btn
    background-color: #1870d5
    color: white
    border: none
    border-radius: 25px
    padding: 15px 70px
    font-size: 0.9em
    font-weight: 600
    &:hover
        opacity: 0.8
        cursor: pointer
    &:active
        opacity: 0.5
.skip-link