@use "theme/palette"
.profile-box
    position: relative
    width: 100%
    z-index: 1
    background: palette.$white
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    border-radius: 1.5rem
    padding: 2rem 0
    .trap-section
        width: 100%
        min-height: 23.5rem
        max-height: 43rem
        // background: palette.$btn-blue
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start
        @media screen and ( max-width: 70rem )
            height: max-content
        .trap-section-title
            height: 2.5rem
            font-weight: 900
            color: palette.$black
            margin-left: .5rem
            margin-bottom: 1rem
        .buttonContainer
            display: flex
            flex-direction: row
            align-items: center
            width: 93%
            margin-left: .5rem
            margin-bottom: 1rem
        .buttonLeftContainer
            display: flex
            flex-direction: row
            justify-content: space-around
            width: 70%
        .buttonRightContainer
            display: flex
            flex-direction: row
            justify-content: space-evenly
            width: 30%
        .filterBtn
            width: 25px
            height: 25px
            &:hover
                opacity: 0.3
                cursor: pointer
        .refreshBtn
            width: 30px
            height: 30px
            &:hover
                opacity: 0.3
                cursor: pointer
        .profileDisable
            width: 30px
            height: 30px
            opacity: 0.1
            cursor: not-allowed
        .trap-section-list
            flex-grow: 1
            overflow-y: scroll
            display: flex
            flex-direction: row
            align-content: flex-start
            justify-content: center
            width: 100%
            margin-left: .5rem
            flex-wrap: wrap
            .trap-btn-active
                width: 100%
                height: 5rem
                text-align: left
                padding-left: 1rem
                border-radius: 10px
                line-height: 2rem
                background: palette.$white
                border: 0.2rem solid #1870d5
                borderWidth: 3
                margin-right: 1.1rem
                margin-bottom: 1rem
                color: palette.$black
                font-weight: 700
                &:hover
                    opacity: 0.8
                    cursor: pointer
                &:active
                    opacity: 0.5
            .trap-btn-nonactive
                width: 100%
                height: 4.5rem
                text-align: left
                padding-left: 1rem
                border: 0.2rem solid #818589
                border-radius: 10px
                borderWidth: 3
                line-height: 2rem
                background: palette.$white
                margin-right: 1.1rem
                margin-bottom: 1rem
                color: palette.$black
                font-weight: 700
                &:hover
                    border: 0.2rem solid #1870d5
                    opacity: 0.5
                    cursor: pointer
                &:active
                    opacity: 0.5
            .trap-section-list-container
                display: flex
                justify-content: space-between
                height: 100%
                width: 100%
                .trap-section-list-left
                    display: flex
                    flex-direction: column
                    width: 100%
                    //height: 100%
                    justify-content: flex-start
                    align-items: flex-start
                    .trap-section-list-info-name
                        height: 25%
                        margin-bottom: 3px
                        //display: flex
                        //flex-direction: row
                        //align-items: center
                        //padding: 0px 0px 0px 0px
                        //border: 3px solid red
                    .trap-section-list-info-location
                        height: 25%
                        //display: flex
                        //flex-direction: row
                        //margin-bottom: 3px
                        //padding: 0
                        //border: 3px solid red
                .trap-section-list-right
                    display: flex
                    flex-direction: row
                    width: 70%
                    align-items: center
                    justify-content: space-around
                    marign: 1rem
                    padding-top: 8px
                   
    

