@use "theme/palette"
.page-bar
    position: relative
    height: 100%
    width: 100%
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    color: palette.$black
    margin-right: 0.5rem
   
    @media screen and ( max-width:  70rem)
        justify-content: center
    &-btn
        width: 5rem
        height: max-content
    &-slider
        flex-direction: column
        width: 55%
        height: 100%
        margin-left: 1rem
        margin-right: 1rem
    &-content
        margin: 0 1rem
        font-weight: 700