@use "theme/palette"

.session-body
  display: flex
  height: 100vh // Full viewport height
  width: 100%

.session-left-block, .session-right-block
  flex: 1 // Each block takes up equal space
  display: flex
  justify-content: center
  align-items: center
  padding: 20px
  box-sizing: border-box

.session-left-block
  background-color: #F5F5F5 // Light gray background for the left block

.session-right-block
  background-color: #F5F5F5 // White background for the right block