@use "theme/palette"
.dashboard
    position: relative
    width: 100%
    height: 100%
    background : #F5F5F5
    .head-bar
        position: relative
        zIndex: 2
        width: 100%
        height: 5rem
        background: palette.$white
    .body
        position: relative
        zIndex: 1
        max-width: 85rem
        margin: auto
        padding: 2rem 0 2rem 0
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: flex-start
        @media screen and ( max-width:  70rem)
            flex-direction: column
            align-items: center
        .left-block
            position: relative
            width: 68%
            display: flex
            flex-direction: column
            justify-content: flex-start
            align-items: center
            @media screen and ( max-width:  70rem)
                width: 80%
                justify-content: center
                align-items: center
            .row
                position: relative
                width: 100%
                margin-bottom: 2rem
                display: flex
                flex-direction: row
                justify-content: flex-start
                align-items: center
                height: max-content
                margin-right: 3rem
                overflow: hidden
                @media screen and ( max-width:  70rem)
                    flex-direction: column
                    justify-content: center
                    width: 100%
                    height: max-content
                    margin-right: 0
                .sm-box
                    position: relative
                    width: 45%
                    height: 8rem
                    margin-right: 3rem
                    overflow: hidden
                    @media screen and ( max-width:  70rem)
                        width: 90%
                        margin-top: 2rem
                        margin-right: 0
                // .lg-box
                //     border: 10px solid red
                //     position: relative
                //     width: 100%
                //     height: max-content
                //     margin-right: 3rem
                //     overflow: hidden
                //     @media screen and ( max-width:  70rem)
                //         width: 100%
                //         height: max-content
                //         margin-right: 0

                .chart-box-compress
                    display: none
                
                @media screen and ( max-width:  70rem)
                    .chart-box
                        display: none
                    .chart-box-compress
                        display: flex

        .right-block-compress
            display: none
        .right-block
            width: 32%
            height: 50%
        @media screen and ( max-width:  70rem)
            .right-block
                display: none
            .right-block-compress
                display: flex
                width: 80%
                height: 50%
                        
            




    