@use "theme/palette"

.toggle
    position: relative
    display: inline-block
    width: 100%
    height: 100%
    border-radius: 2.3rem
    overflow: hidden
    .toggle-checkbox
        opacity: 0
        width: 0
        height: 0
    .toggle-slider
        position: absolute
        cursor: pointer
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: #1870d5
        font-weight: 700
        display: flex
        flex-direction: row
        justify-content: space-evenly
        .toggle-slider-text
            color: palette.$white
            line-height: 2.5rem
        &::before
            position: absolute
            content: "temp"
            line-height: 2rem
            height: 80%
            width: 50%
            top: 50%
            left: 0.24rem
            transform: translateY(-50%)
            border-radius: 2.3rem
            background: palette.$white
            transition: 0.4s
            color: #1870d5
    input.toggle-checkbox:checked 
        + .toggle-slider:before
                transform: translate(90%, -50%)
                content: "humd"
