@use "theme/palette"

* 
  box-sizing: border-box

body 
  margin: 0

.container 
  margin: 1rem
  text-align: center

.navbar
  background-color: white
  color: #1870d5
  display: flex
  justify-content: space-between
  //align-items: stretch
  //gap: 2rem
  padding: 0 3rem
  height: 65px
  width: 100%
  //border: 2px solid brown
  position: relative

  ul 
    padding: 0
    margin: 0
    list-style: none
    display: flex
    gap: 1rem
  a 
    color: inherit
    text-decoration: none
    height: 100%
    display: flex
    align-items: center
    padding: .25rem
    cursor: pointer
  .logout
    &:hover
      opacity: 0.8

  li:hover
    opacity: 0.8

  .right
    display: flex
    margin-right: 0.7rem
    //border: 3px solid green
    .dropdown
      float: left
      overflow: hidden
      //border: 3px solid yellow
      .dropbtn
        display: none 
        background-color: white
        border: none
        color: inherit
        text-decoration: none
        height: 64%
        margin-block: auto
        padding: .25rem
        cursor: pointer
        font-size: inherit
        //border: 2px solid green
        justify-content: center
        justify-self: center
        align-self: center
        align-items: center
        margin-top: 0.66rem
        content: url(../../assets/three_bar.png)
        &:hover
          content: url(../../assets/three_bar_lightblue.png)
      .dropdown-content
        display: none
        position: absolute
        z-index: 1
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)
        a
          color: inherit
          padding: 12px 16px
          display: flex
          text-align: left
          //border: 3px solid green
      .show
        display: flex
        flex-direction: column
        //border: 3px solid cyan
        background-color: #F5F5F5
    @media screen and ( max-width: 70rem)
      .dropdown
        .dropbtn
          display: flex


.logo
  display: flex
  font-size: 2rem
  //border: 3px solid red
  @media screen and ( max-width: 38rem)
    //font-size: 1rem

.navbar-overlap
  background-color: white
  color: #1870d5
  display: flex
  padding: 0 3rem
  height: 65px
  width: 51%
  //border: 2px solid brown
  justify-content: center
  margin-inline: auto
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  @media screen and ( max-width: 70rem)
    display: none
  ul 
    padding: 0
    margin: 0
    list-style: none
    display: flex
    gap: 1rem
  li:hover
    opacity: 0.8
  .middle
    margin-inline: auto
    justify-content: center
    //border: 3px solid red
    a 
      color: inherit
      text-decoration: none
      height: 100%
      display: flex
      align-items: center
      padding: .25rem
      cursor: pointer
      //border: 3px solid blue
    .dropdown
      float: left
      overflow: hidden
      //border: 3px solid yellow
      .dropbtn
        display: none 
        background-color: white
        border: none
        color: inherit
        text-decoration: none
        height: 100%
        margin-block: auto
        padding: .25rem
        cursor: pointer
        font-size: inherit
        //border: 2px solid green
        justify-content: space-between
        justify-self: center
        align-self: center
        align-items: center
        &:hover
          color: lightBlue 
          //background-color: #1870d5
      .dropdown-content
        display: none
        position: absolute
        z-index: 1
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)
        a
          color: inherit
          padding: 12px 16px
          display: flex
          text-align: left
          //border: 3px solid green
      .show
        display: flex
        flex-direction: column
        //border: 3px solid cyan
        background-color: #F5F5F5
    @media screen and ( max-width: 70rem)
      .four-links
        display: none