@use "theme/palette"
.info-bar
    width: 100%
    height: 2rem
    margin-bottom: 0.5rem
    display: flex
    flex-direction: row
    justify-content: center
    align-items: flex-start
    border-radius: 0.6rem
    overflow: hidden
    line-height: 2rem
    //border: 2px solid red
    @media screen and ( max-width: 70rem )
        width: 100%
    .info-bar-title
        width: 50%
        height: 100%
        background: #F5F5F5
        color: palette.$black
        font-weight: 700
    .info-bar-content
        width: 40%
        height: 100%
        background: #F5F5F5
        color: palette.$black
        font-weight: 700
        //border: 2px solid blue
        padding-left: 25px
        .is-x
            font-weight: 800
            color: black
    .edit-btn-content
        width: 10%
        height: 100%
        background: #F5F5F5
        color: palette.$black
        font-weight: 700
        //border: 2px solid blue
    .edit-btn 
        width: 1.5rem
        margin-top: 3px
    .edit-btn:hover
        opacity: 0.3
        cursor: pointer
    