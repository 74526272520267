@use "theme/palette"

* 
  box-sizing: border-box

body 
  margin: 0

.footer
    display: flex
    flex-direction: row
    justify-content: center
    position: relative
    left: 0
    bottom: 0
    width: 100%
    background-color: white
    color: #1870d5
    text-align: center
    padding: 5px 0 5px 0

.imgContainer
    display: flex
    justify-content: center
    margin: 5px 3px 3px 3px
   
.footerImg
    width: 70px
    height: 70px
    

.contact
    margin: 3px

.header
    margin-bottom: 0
    
.txt
    margin-bottom: 0
    

