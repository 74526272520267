@use "theme/palette"
.chart-box
    position: relative
    height: 100%
    width: 100%
    background: palette.$white
    border-radius: 1.5rem
    border: none
    outline: none
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-content: stretch
    color: palette.$black
    overflow: hidden
    .head-bar
        position: relative
        height: 4rem
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        margin-top: 1rem
        .title
            position: relative
            margin-left: 15px
        
        .title-txt 
            font-size: 1.5rem
            font-weight: 900
            
        .toggle-box
            width: 11.5rem
            height: 2.5rem
            margin-right: 2rem
            margin-top: 10px
    .content
        position: relative
        display: flex
        justify-content: center
        align-items: center
        height: 20rem
        margin: 0.5rem 0
        @media screen and ( max-width: 70rem )
            height: 20rem
        .chart
            width: 96%
            height: 95%
            position: absolute
            left: 50%
            transform: translateX(-50%)
          
                
