@use "theme/palette"
//trap-functions
.functions-box
  margin-left: 4.7rem

.custom-button
  &.MuiButton-root
    text-transform: capitalize
    margin-left: -0.8rem

    &.MuiButton-outlined
        text-transform: uppercase
        border: none

    &:hover
      border: none
      background-color: transparent
      color: lightBlue

.custom-paper
    min-height: 16rem

.function-icons
  margin-left: 0.4rem
  margin-right: 0.35rem 
  width: 2.0rem

.loading-icon
  position: absolute
  top: 50%
  left: 50%
  margin-right: -50%
  transform: translate(-50%, -50%)

.alert
  margin-top: 1.7rem