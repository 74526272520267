@use "theme/palette"

.account-box
    position: relative
    height: 100%
    width: 100%
    min-width: 50%
    background: palette.$white
    border-radius: 1.5rem
    border: none
    outline: none
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding: 2% 2% 2% 2%
    color: palette.$black
    margin-top: 0.5rem
    margin-bottom: 0.5rem
    //overflow: auto
.account-container
    width: 100%
    background: palette.$white
    padding: 1px 1px 1px 1px
    display: flex
    flex-direction: row
    justify-content: space-between
    //border: 2px solid blue

.account-title
    width: 100%
    display: flex
    flex-direction: column
    justify-content: flex-start
    //border: 1px solid green
.account-title p
    margin: 0
    padding: 0
    line-height: 25px
    text-align: left

.account-title h2
    margin: 0
    padding: 0
    line-height: 30px
    text-align: left

.account-btn
    align-self: center

.account-button
    width: 100%
    height: 2rem
    padding: 0px 15px 0px 15px
    border-radius: 1rem
    border: none
    font-size: 0.8em
    color: #ffffff
    background: #1870d5
    font-weight: 400
    &:hover
        opacity: 0.9
        cursor: pointer
    &:active
        opacity: 0.5
.submit-btn
    margin-top: 5%
.changeAccountInfoInput
    border: 1px solid #1870d5
    border-radius: 10px
    font-size: 1em
    font-weight: 400
    padding: 10px

.spinner 
    width: 64px
    height: 64px
    border: 8px solid
    border-color: #3d5af1 transparent #3d5af1 transparent
    border-radius: 50%
    animation: spin-anim 1.2s linear infinite

.icon-img 
    height: calc(2em * 1.2)
    width: calc(2em * 1.2)

.account-division-container
    display: flex
    width: 100%
    justify-content: space-between
    flex-direction: row
    //border: 2px solid red
.account-days-container
    display: flex
    flex-direction: column
    margin-left: 0.25rem
    //border: 2px solid green
.row-container 
    display: flex
    flex-direction: row
    align-items: center

.text-container 
    display: flex
    flex-direction: row
    align-items: center
    margin-left: 15px


.num-container 
    display: flex
    width: 30px
    flex-direction: row
    margin-right: 10px
    margin-left: 5px

.switch-header-text
    display: flex
    width: 82px
    flex-direction: row
    font-weight: bold
.switch-text
    display: flex
    width: 82px
    flex-direction: row
    
.row-container-days
    display: flex
    flex-direction: row
    align-items: center
.right-container   
    display: flex
    flex-direction: row
    width: 70%
    justify-content: center
    //border: 2px solid blue

.email-list-title-container
    display: flex
    flex-direction: column
    width: 100%
    //border: 2px solid black
    height: 100%
    align-items: center

.email-list-title
    font-weight: bold
    margin-top: 5px
    margin-bottom: 5px

.email-list-emails
    display: flex
    height: 90%
    width: 90%
    flex-direction: column
    //border: 2px solid red

.email-list-items
    display: flex
    flex-direction: row
    border: 2px solid grey
    padding: 1%
    margin: 1%
    align-items: center
    justify-content: space-between

.remove-item
    width: 35
    height: 35
    border-radius: 10px
    text-decoration: none
    border: 2px solid #FF0000
    background: #FF000080
    padding: 3px
    padding-left: 10px
    padding-right: 10px
    font-size: 20px
    margin-left: 5px
    margin-right: 5px
    &:hover
        opacity: 0.9
        cursor: pointer
    &:active
        opacity: 0.5

.email-button
    width: 100px
    height: 3rem
    padding: 0px 15px 0px 15px
    border-radius: 1rem
    border: none
    font-size: 0.8em
    color: #ffffff
    background: #1870d5
    font-weight: 400
    &:hover
        opacity: 0.9
        cursor: pointer
    &:active
        opacity: 0.5

.email-btn
    align-self: center
    width: 50%
    height: 2rem 

.myOverlay 
  background-color: rgba(189, 195, 199, 0.5)


.myModal 
  width: 25%
  display: flex
  flex-direction: column
  align-items: center
  margin: auto
  margin-top: 30%
  padding: 1%
  padding-top: 0%
  
  background-color: white
  border-radius: 10px
  border: 2px solid grey

.myModal input  
    width: 80%
    height: 15%
    font-size: 16px

.email-button-input-container
    display: flex
    justify-content: space-between
    width: 80%
    margin: 3%
    //border: 2px solid blue

.myModalRemove
  width: 25%
  display: flex
  flex-direction: column
  align-items: center
  margin: auto
  margin-top: 30%
  padding: 1%
  padding-top: 0%
  background-color: white
  border-radius: 10px
  border: 2px solid grey

.myModalRemove h2   
    line-height: 20px
    margin-bottom: 4%

.email-button-input-container-remove
    display: flex
    justify-content: space-between
    width: 80%
    margin: 3%
    //border: 2px solid blue

.email-button-modal
    width: 100px
    height: 2rem
    padding: 0px 15px 0px 15px
    border-radius: 1rem
    border: none
    font-size: 0.8em
    color: #ffffff
    background: #1870d5
    font-weight: 400
    &:hover
        opacity: 0.9
        cursor: pointer
    &:active
        opacity: 0.5

