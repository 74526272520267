@use "theme/palette"

.data-box
  position: relative
  height: 100%
  width: 100%
  min-width: 50%
  background: #ffffff // White
  border-radius: 1.5rem
  border: none
  outline: none
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  padding: 2% 2% 2% 2%
  color: #000000 // Black
  margin-top: 0.5rem
  margin-bottom: 0.5rem

.data-container
  width: 100%
  background: #ffffff // White
  padding: 1px 1px 1px 1px
  display: flex
  flex-direction: row
  justify-content: space-between

.data-title
  width: 100%
  display: flex
  flex-direction: column
  justify-content: flex-start

.data-title p
  margin: 0
  padding: 0
  line-height: 25px
  text-align: left

.data-title h2
  margin: 0
  padding: 0
  line-height: 30px
  text-align: left
  margin-bottom: 0.5rem

.data-division-container
  display: flex
  width: 100%
  justify-content: space-between
  flex-direction: row


.data-days-container
  display: flex
  flex-direction: column
  margin-left: 1rem


.row-container 
  width: 100%
  flex-direction: row
  align-items: center
  justify-content: space-around

.right-container   
  display: flex
  flex-direction: row
  width: 70%
  justify-content: center

.data-list-title-container
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  align-items: center

.data-list-title
  font-weight: bold
  margin-top: 5px
  margin-bottom: 5px

.month-list-months
  display: flex
  height: 90%
  width: 30%
  flex-direction: column

  select
    max-height: 2rem // Match the height of the button
    width: 100px // Match the width of the button
    padding: 0px 5px 0px 5px
    border: 1px solid #000000 // Add border
    border-radius: 0 // Remove curved edges
    font-size: 0.8em
    color: #000000 // Black
    background: #ffffff // White
    font-weight: 400
    &:hover
      opacity: 0.9
      cursor: pointer
    &:active
      opacity: 0.5

.fixed-length-dropdown 
  margin-top: 0.25rem
  max-height: 2px 
  overflow-y: auto
  display: block

.data-button
  width: 100px
  height: 2rem
  padding: 0px 5px 0px 5px
  border-radius: 1rem
  border: none
  font-size: 0.8em
  color: #ffffff
  background: #1870d5
  font-weight: 400
  display: flex
  align-items: center // Align text vertically
  justify-content: center // Center text horizontally
  &:hover
    opacity: 0.9
    cursor: pointer
  &:active
    opacity: 0.5


.modal-backdrop 
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: rgba(0, 0, 0, 0.5)
  display: flex
  justify-content: center
  align-items: center

.modal 
  background: white
  padding: 20px 20px 10px 20px // Reduce bottom padding to 10px
  border-radius: 5px
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1)

.modal-content
  display: flex
  flex-direction: column // Stack children vertically
  align-items: center
  margin-bottom: 20px

.close-button
  margin-top: 10px
  padding: 0.5rem 1rem
  border: none
  color: #ffffff
  background: #1870d5
  cursor: pointer
  &:hover
    opacity: 0.9

.tab-bar
  display: flex
  flex-direction: column // Stack buttons vertically
  align-items: flex-start // Align buttons to the start
  margin-bottom: 1rem

.tab-bar button
  padding: 0.5rem 1rem
  border: none
  background: #d3d3d3 // Light Gray
  cursor: pointer
  width: 100%
  margin-bottom: 0.5rem // Add margin between buttons
  height: 2rem // Ensure the height matches the data list title

.tab-bar button.active
  background: #1870d5 // Primary Color
  color: #ffffff // White