@use "theme/palette"
.info-box
    position: relative
    height: 100%
    width: 100%
    background: palette.$white
    border-radius: 1.5rem
    border: none
    outline: none
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    color: palette.$black
    overflow: auto
    @media screen and ( max-width:  70rem)
        flex-direction: column
        justify-content: center
        overflow: auto
    .top-container
        display: flex
        flex-direction: row
        width: 100%
        justify-content: space-between
        @media screen and ( max-width:  70rem)
            flex-direction: column
            justify-content: center    
    .right-info
        margin: 2rem
        //margin-left: 3rem
        width: 100%
       
        @media screen and ( max-width:  70rem)
            margin-left: 0rem
        .info-bar1
            width: 100%
            height: 2rem
            margin-bottom: 0.5rem
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: flex-start
            border-radius: 0.6rem
            overflow: hidden
            line-height: 2rem
            @media screen and ( max-width: 70rem )
                width: 90%
                margin-inline: auto
            &.info-bar1-page-bar
                height: 4rem
            .info-bar-title1
                width: 50%
                height: 100%
                background: #1870d5
                color: palette.$white
            .info-bar-content1
                width: 50%
                height: 100%
                background: #F5F5F5
                color: palette.$black
                font-weight: 700
            
    .pic-section
        //display: inline-block   
        display: flex
        justify-content: center
        align-items: center
        overflow: hidden
        position: relative
        width: 650px
        height: 20rem
        margin: 2rem
        min-width: 330px
        border-radius: 51%
        border: 1px solid
        border-color: palette.$black
        //margin-left: 0rem
        //border: 2px solid blue
        z-index: 0
        &:hover
            opacity: 1
            cursor: pointer
            border-color: lightBlue
        @media screen and ( max-width:  70rem)
            height: 12rem
            min-width: 12rem
            width: 12rem
            border-radius: 50%
            margin-left: auto
            margin-right: auto
            margin-top: 2rem
            margin-bottom: auto
            
        .pic
            height: 100%
            //width: auto
            z-index: 1
            //margin-left: -3.5rem
            //border-radius: 50%
            //border: 2px solid red
        .pic-logo
            height: 100%
            width: auto
            z-index: 1
            border-radius: 50%
        .pic-hover-content
            display: none
            
        .pic:hover ~ .pic-hover-content
            display: block
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            color: rgba(0, 0, 0, 0.7)
            font-size: 2rem
            z-index: 0
    .pic-section-content-nonactive
        width: 90%
        height: 0rem
        margin: 0rem 0
        margin-left: 2rem
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: center
        @media screen and ( max-width:  70rem)
            height: 0rem
            width: 90%
            justify-content: flex-start
            align-items: flex-start
            margin-left: 0
            margin-bottom: 1rem
    .pic-section-content-active
        width: 90%
        height: 20 rem
        margin: 0rem
        margin-left: 2rem
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: center
        @media screen and ( max-width:  70rem)
            height: max-content
            width: 90%
            justify-content: flex-start
            align-items: flex-start
            margin-left: 0
    .location-section
        display: flex
        color: palette.$black
        font-weight: 700
    .showmore
        color: palette.$white
        display: inline-block
        background-color: palette.$white
        font-weight: 200
        margin-right: 0rem
        font-size: 1rem
        padding: 3px
        border-right: 1px
        margin-bottom: 1rem
    .collumns
        display: flex
        width: 96%
        margin-right: 5px
       
    .collumn1
        width: 100%
        
    .collumn2
        width: 100%

    .icon-container
        display: flex
        justify-content: center
        gap: 1rem
        margin-top: 3rem
        margin-bottom: 1rem

    .edit
        display: flex
        flex-direction: row-reverse
        @media screen and ( max-width:  70rem)
            margin-right: 2rem

    .img
        display: flex
        padding: 0.25rem

    .edit-txt
        color: #1870d5
        font-size: 15px
    
    .edit-txt:hover
        opacity: .5
        cursor: pointer
        text-decoration: underline
    
    .arrow 
        border: solid black
        border-width: 0 4px 4px 0
        display: inline-block
        padding: 3px
        width: 18px
        height: 18px

    .arrow:hover
        opacity: .5
        cursor: pointer

    .up 
        transform: rotate(-135deg)
        -webkit-transform: rotate(-135deg)
        margin-top: 2rem

    .down 
        transform: rotate(45deg)
        -webkit-transform: rotate(45deg)

    .settings
        width: 1.5rem
        &:hover
            opacity: 0.3
            cursor: pointer

    .settingsDisabled
        width: 1.5rem
        opacity: 0.1
        cursor: not-allowed
        






