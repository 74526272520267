@use "theme/palette"
.btn 
    position: relative
    width: 100%
    height: 2rem
    border-radius: 1rem
    font-size: 1.2rem
    color: #ffffff
    font-weight: 500
    &:hover
        opacity: 0.3
        cursor: pointer
    .btn-content
        width: 100%
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

.btnDisable 
    position: relative
    width: 100%
    height: 2rem
    border-radius: 1rem
    font-size: 1.2rem
    color: #ffffff
    font-weight: 500
    opacity: 0.1
    .btn-content
        width: 100%
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

.btn-yellow
    background: #818589
.btn-green
    background: #818589
.btn-blue
    background: #1870d5