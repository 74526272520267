@use "theme/palette"

.account-box
    position: relative
    height: 100%
    width: 100%
    min-width: 50%
    background: palette.$white
    border-radius: 1.5rem
    border: none
    outline: none
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding: 2% 2% 2% 2%
    color: palette.$black
    margin-top: 0.5rem
    margin-bottom: 0.5rem
    //overflow: auto
.account-container
    width: 100%
    background: palette.$white
    padding: 1px 1px 1px 1px
    display: flex
    flex-direction: row
    justify-content: space-between
    //border: 2px solid blue
.temp-container
    width: 100%
    background: palette.$white
    padding: 1px 1px 1px 1px
    display: flex
    flex-direction: column
    justify-content: space-between
    //border: 2px solid blue
.account-title
    width: 80%
    display: flex
    flex-direction: column
    justify-content: flex-start
    //border: 1px solid green
.account-title p
    margin: 0
    padding: 0
    line-height: 25px
    text-align: left
    margin-left: 0.25rem

.account-title h2
    margin: 0
    padding: 0
    line-height: 30px
    text-align: left

.account-btn
    align-self: center

.account-button
    width: 100%
    height: 2rem
    padding: 0px 15px 0px 15px
    border-radius: 1rem
    border: none
    font-size: 0.8em
    color: #ffffff
    background: #1870d5
    font-weight: 400
    &:hover
        opacity: 0.9
        cursor: pointer
    &:active
        opacity: 0.5
.submit-btn
    margin-top: 5%
.changeAccountInfoInput
    border: 1px solid #1870d5
    border-radius: 10px
    font-size: 1em
    font-weight: 400
    padding: 10px

.spinner 
    width: 64px
    height: 64px
    border: 8px solid
    border-color: #3d5af1 transparent #3d5af1 transparent
    border-radius: 50%
    animation: spin-anim 1.2s linear infinite

//Account threshold
.account-box-threshold
    position: relative
    height: 100%
    width: 100%
    min-width: 50%
    background: palette.$white
    border-radius: 1.5rem
    outline: none
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    color: palette.$black
    //overflow: auto
    margin-top: 5px


.icon-img 
    height: calc(2em * 1.2)
    width: calc(2em * 1.2)
.row-temp-container 
    display: flex
    flex-direction: row
    justify-content: flex-start
    //align-items: center
    justify-content: flex-start
    padding-top: 5px
    padding-bottom: 5px
    margin-left: 0.5rem

.text-container 
    display: flex
    flex-direction: row
    align-items: center
    margin-left: 15px

.temp-text
    margin-top: 0.50rem
    margin-right: 2rem

.num-container 
    display: flex
    width: 30px
    flex-direction: row
    margin-right: 10px
    margin-left: 5px

.incDisableGreen
    width: 35
    height: 35
    border-radius: 10px
    border: 2px solid #FF0000
    background: #FF000080
    padding: 3px
    padding-left: 7px
    padding-right: 7px
    font-size: 20px
    margin-left: 5px
    margin-right: 5px

.decDisableGreen
    width: 35
    height: 35
    border-radius: 10px
    border: 2px solid #FF0000
    background: #FF000080
    padding: 3px
    padding-left: 10px
    padding-right: 10px
    font-size: 20px
    margin-left: 5px
    margin-right: 5px

.incGreen
    width: 35
    height: 35
    border-radius: 10px
    border: 2px solid #1870d5
    text-decoration: none
    background: #1870d580
    padding: 3px
    padding-left: 7px
    padding-right: 7px
    font-size: 20px
    margin-left: 5px
    margin-right: 5px

.decGreen
    width: 35
    height: 35
    border-radius: 10px
    border: 2px solid #1870d5
    text-decoration: none
    background: #1870d580
    padding: 3px
    padding-left: 10px
    padding-right: 10px
    font-size: 20px
    margin-left: 5px
    margin-right: 5px

.account-button-threshold
    width: 80px
    height: 2rem
    padding: 0px 15px 0px 15px
    border-radius: 1rem
    border: none
    font-size: 0.8em
    color: #ffffff
    background: #1870d5
    font-weight: 400
    &:hover
        opacity: 0.9
        cursor: pointer
    &:active
        opacity: 0.5

.account-btn-threshold
    align-self: center
    margin-right: 10px

button:hover 
    opacity: 0.9
    cursor: pointer

@keyframes spin-anim 
    0% 
        transform: rotate(0deg)
    
    100% 
        transform: rotate(360deg)
    
