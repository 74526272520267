.container
    width: 100%
    height: 90vh
    display: flex
    flex-direction: column
    justify-content: center
    align-self: center
    padding: 30px
    //border: 2px solid red
.email-container
    //border: 2px solid blue
    height: 50%
    display: flex
    flex-direction: column
    justify-content: space-around
.email-btn
    background-color: #1870d5
    color: white
    border: none
    border-radius: 25px
    padding: 15px 40px
    font-size: 0.9em
    font-weight: 600
    &:hover
        opacity: 0.8
        cursor: pointer
    &:active
        opacity: 0.5

