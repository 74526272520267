@use "theme/palette"
.registration-error
    //border: 2px solid green
    display: flex
    align-self: center
    text-align: center
    margin-bottom: 10px
.registration-error-text
  font-size: 1.0em
  font-weight: 600
  color: red
.panel
  width: 100%
  margin-bottom: 100px
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start
  .form-field
    margin-bottom: 40px
    width: 100%
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    &-label
      width: 85%
      display: block
      text-transform: uppercase
      font-size: 0.9em
      color: #1870d5
      text-align: left
    &-error-label
      width: 85%
      display: block
      font-size: 0.9em
      color: red
      text-align: left
    &-input
      width: 85%
      background-color: transparent
      border: none
      color: #707c8b
      outline: none
      border-bottom: 1px solid #1870d5
      font-size: 1em
      font-weight: 300
      padding-bottom: 10px
      margin-top: 10px

    &-input::placeholder
      color: #707c8b

    &-button
      background-color: #1870d5
      color: white
      border: none
      outline: none
      border-radius: 25px
      padding: 15px 70px
      font-size: 0.8em
      font-weight: 500
      margin-bottom: 1.5rem
      &:hover
        opacity: 0.8
        cursor: pointer
      &:active
        opacity: 0.5

    &-link
      color: #66707d
      text-decoration: none
      display: inline-block
      border-bottom: 1.5px solid #1870d5
      margin-top: 10px

