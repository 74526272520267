@use "theme/palette"
.probeDataSession-box
  display: flex
  height: 100% // Full viewport height
  width: 100%
  flex-direction: column
  align-items: center
  background: palette.$white
  border-radius: 1.5rem
  border: none
  outline: none
  
.probeDataSession-container
   width: 100%
.session-top-half
    display: flex
    flex-direction: column
    //border: 2px solid blue
    justify-content: center
    align-items: center
.session-pic-section
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden
    position: relative
    width: 325px
    height: 20rem
    margin: 2rem
    min-width: 200px
    border-radius: 60%
    border: 1px solid
    border-color: palette.$black
    //margin-left: 0rem
    //border: 2px solid blue
    z-index: 0
    &:hover
        opacity: 1
        cursor: pointer
        border-color: lightBlue
    @media screen and ( max-width:  70rem)
        height: 12rem
        min-width: 12rem
        width: 12rem
        border-radius: 50%
        margin-left: auto
        margin-right: auto
        margin-top: 2rem
        margin-bottom: auto
        
    .pic
        height: 100%
        z-index: 1
        
    .pic-logo
        height: 100%
        width: auto
        z-index: 1
        border-radius: 50%
    .pic-hover-content
        display: none
        
    .pic:hover ~ .pic-hover-content
        display: block
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        color: rgba(0, 0, 0, 0.7)
        font-size: 2rem
        z-index: 0

.session-bottom-half
    display: flex
    flex-direction: column
    width: 100%
.info-container
  display: flex
  width: 100%
  //border: 2px solid red 
.session-bottom-container
    display: flex 
    width: 100%
    flex-direction: row
    justify-content: space-around
    align-items: flex-start  
.noDataTextContainer
    display: flex
    width: 100%
    height: 100%
    //border: 2px solid red
    justify-content: center
    align-items: center
.session-bottom-left
    width: 40%

.session-bottom-right
    width: 40%

.delete-button-container
  margin-top: 20px
  display: flex
  justify-content: center
  width: 100%

.delete-button
  padding: 10px 20px
  background-color: #d9534f
  color: #fff
  border: none
  border-radius: 5px
  cursor: pointer
  &:hover
    background-color: #c9302c