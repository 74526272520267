.continue-main-container
    width: 100%
    height: 90vh
    display: flex
    flex-direction: column
    //border: 2px solid red
.continue-top-form
    display: flex
    flex: 1
    align-items: center
    padding: 0px 10px 0px 10px
    //border: 2px solid green
.continue-bottom-form    
    flex: 10
    //margin-left: 30px
    //margin-right: 30px
    //padding: 20px
    text-align: center
    //border: 2px solid blue
.continue-form-container
    width: 100%
    height: 20%
    display: flex
    flex-direction: column
    //padding-left: 80px
    padding: 10px 0px 10px 80px
    margin-bottom: 20px
    //border: 2px solid green
.continue-btn-container
    display: flex
    flex-direction: column
    height: 50%
    align-items: center
    justify-content: space-between
    //margin-top: 10px
    padding-top: 20px
    //border: 2px solid purple
.continue-form-title 
    width: 85%
    display: block
    text-transform: uppercase
    font-size: 0.9em
    color: #1870d5
    text-align: left
.continue-input
    width: 85%
    background-color: transparent
    color: #707c8b
    border: 1px solid #1870d5
    border-radius: 10px
    font-size: 1em
    font-weight: 400
    padding: 10px
.continue-title
    margin-bottom: 0px
    //border: 2px solid black
.continue-txt
    margin-top: 5px
    //border: 2px solid black
.continue-error
    color: red
    margin-top: 2px
    margin-bottom: 0px
    text-align: left
.continue-back-btn
    background-color: #1870d5
    color: white
    border: none
    border-radius: 25px
    padding: 10px 20px 10px 20px
    font-size: 0.8em
    font-weight: 600
    &:hover
        opacity: 0.8
        cursor: pointer
    &:active
        opacity: 0.5
.continue-send-btn
    //margin-top: 20px
    background-color: #1870d5
    color: white
    border: none
    border-radius: 25px
    padding: 15px 70px
    font-size: 0.9em
    font-weight: 600
    &:hover
        opacity: 0.8
        cursor: pointer
    &:active
        opacity: 0.5