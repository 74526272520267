@use "theme/palette"

.upload-box
    position: relative
    height: 100%
    width: 100%
    min-width: 50%
    background: palette.$white
    border-radius: 1.5rem
    border: none
    outline: none
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding: 5% 2% 5% 2%
    color: palette.$black
    //overflow: auto
.upload-container
    width: 100%
    background: palette.$white
    padding: 5px 5px 5px 5px
    display: flex
    flex-direction: row
    justify-content: space-between
    //border: 2px solid blue

.upload-title
    width: 80%
    display: flex
    flex-direction: column
    justify-content: flex-start
    //border: 1px solid green
.upload-title p
    margin: 0
    padding: 0
    line-height: 25px
    text-align: left

.upload-title h2
    margin: 0
    padding: 0
    line-height: 30px
    text-align: left

.account-btn
    align-self: center

.account-button
    width: 100%
    height: 2rem
    padding: 0px 15px 0px 15px
    border-radius: 1rem
    border: none
    font-size: 0.8em
    color: #ffffff
    background: #1870d5
    font-weight: 400
    &:hover
        opacity: 0.9
        cursor: pointer
    &:active
        opacity: 0.5
.upload-paragraph
    margin: 0
    margin-top: 1rem
    margin-left: 0.5rem
    padding: 0
    line-height: 25px
    text-align: left
.submit-btn
    margin-top: 5%
.changeAccountInfoInput
    border: 1px solid #1870d5
    border-radius: 10px
    font-size: 1em
    font-weight: 400
    padding: 10px

.spinner 
    width: 64px
    height: 64px
    border: 8px solid
    border-color: #3d5af1 transparent #3d5af1 transparent
    border-radius: 50%
    animation: spin-anim 1.2s linear infinite


@keyframes spin-anim 
    0% 
        transform: rotate(0deg)
    
    100% 
        transform: rotate(360deg)
    
