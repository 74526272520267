@use "theme/palette"

.canvas
  text-align: center
  background-color: palette.$light-blue
  width: 100vw
  min-height: calc(100vh - 34px)
  // height: max-content
  // overflow-x: hidden
  // overflow-y: visible

