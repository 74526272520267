@use "theme/palette"

.upload
   position: relative
   width: 100%
   height: 100%
   background : #F5F5F5
   min-height: calc(100vh - 34px)
   display: flex
   align-items: center
   box-sizing: content-box
   flex-direction: column
.upload-body
   position: relative
   width: 40%
   height: 100%
   display: flex
   flex-direction: column
   align-content: center
   padding-top: 2%
   //padding-bottom: 3%
   margin-bottom: 2%
.account-title-container
   padding-left: 2%
   margin-bottom: 1rem
.account-body h1
   line-height: 15px
   text-align: left