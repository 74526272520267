@use "theme/palette"

.dataDownload
   position: relative
   width: 100%
   height: 100%
   background : #F5F5F5
   min-height: calc(100vh - 34px)
   display: flex
   justify-content: flex-start 
   align-items: center
   flex-direction: column
.dataDownload-body
   position: relative
   width: 50%
   height: 100%
   display: flex
   flex-direction: column
   align-content: center
   padding-top: 2%
   //padding-bottom: 3%
   margin-bottom: 2%
.dataDownload-title-container
   padding-left: 2%
   margin-bottom: 1rem
.dataDownload-body h1
   line-height: 15px
   text-align: left