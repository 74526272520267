.verification-container
    width: 100%
    height: 90vh
    display: flex
    flex-direction: column
    justify-content: center
    padding: 30px
    //border: 2px solid red
.verify-container
    //border: 2px solid blue
    height: 70%
    display: flex
    flex-direction: column
    justify-content: space-around
.verification-input-container
    align-self: center
    //border: 2px solid green
.verify-title
    margin-bottom: 0px
    //border: 2px solid red
.verify-txt
    margin-top: 0px
    //border: 2px solid red
.verify-btn-container
    display: flex
    flex-direction: column
    align-self: center
    height: 50%
    padding: 0px 30px 0px 30px
    margin-top: 10px
    //border: 2px solid green
.verify-btn
    background-color: #1870d5
    color: white
    border: none
    border-radius: 25px
    padding: 15px 40px
    font-size: 0.9em
    font-weight: 600
    &:hover
        opacity: 0.8
        cursor: pointer
    &:active
        opacity: 0.5
.verify-registration-error
    //border: 2px solid green
    display: flex
    align-self: center
    text-align: center
    margin-top: 10px
.verify-registration-error-text
  font-size: 1.0em
  font-weight: 600
  color: red